<template>
  <div class="loggedInCheckoutDetails">
    <div class="group address">
      <span class="group-title d-block mb-25">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVR4nO2cPWsUURSGH4hELSLaqtiJYBcbf4J2ttqIlZUfpa2dEf0DVhYK1lrpH/CjMVYSsFJMLIMGVFA8MjALcVw2ye6de9+5+z7wNlkyc87D5WTPTlgwxhhjjDH/cgC4C2wAX4CV9mcmIUeB10B08hY4YdNpWAY+jpE8yjpw1rJn4yLwfYLkUX4CVyx77yy0Mzj2mAfAvinuN5csAU+nkDzKc+Bw6SbUOQWszSB5lLX2WjuxuO2dTHfur7SvV8d5YDOB5FE222tOYqfx1LxeFVeBXwklj/IbuDXhvt2T3E3zfr0K9gMPexDczRPg4Jj77+Z3q11CoqeMW26qF728wxLSV7rLTdWiL+1yCekr25ebKkUvAPcLCu7mXo2il4BnAnKnyWA4CbwXEFa16HOJl5Cw6P+52S4MMfAw70tIzLPo3EtIzKPoM8AnATFRs+jLwA8BKdFDmt4G+yQkBpaiT24ODXgJiSnyAjiSW/LQl5CYMh+A00N9EhIDyzfgQt+Sa1lCYsb8AW73Ibh5SPlYoMEQy6PUD3DvCDQVomncJGNdoKEQTfOA16LpX/TnlKLnYSkJhdGx2Mr2CKHMfzPFnKUYpRsPiy4vJXyiKS7MowPteEZj0cVPoU805cV5dFBe6iBmtK+TGIvOhEVnwqIzYdGZsOhMWHQmLDoTFp0Ji86ERWfCojOh9iFOiNWTDLXGQqyeZKg1FmL1JEOtsRCrJxlqjYVYPclQayzE6kmGWmMhVk8y1BoLsXqSodZYiNWTDLXGQqyeZKg1FmL1JEOtsRCrJxlbGZoLkXwtKfqNgIDIlFclRd8QEBCZcq301/q8E5AQPWdV4SuOj1cuexU4hgjNyb7ezrEa/kBuAS/bcVH8JBtjjDHGGGOMMcYgxl+nBIeTfLmCTQAAAABJRU5ErkJggg=="
          width="45" />
        {{ $t("shipping_address") }}</span>
      <div v-for="(address, index) in addresses" :key="`u-${index}`">
        <div @click="selectAddress(address.id)" class="address-item border rounded p-5 m-5"
          :class="address.id == selectedShippingAddressId ? 'selected-item' : ''">
          <div v-if="address.company != null">{{ address.company }}</div>
          <div>{{ address.firstname }} {{ address.lastname }}</div>
          <div>{{ address.street[0] }}</div>
          <div>{{ address.postcode }} {{ address.city }}</div>
          <div>{{ address.telephone }}</div>
        </div>
      </div>
      <b-form-checkbox id="new-address" name="new-address" label-for="new-address" v-model="newAddress">{{
        $t("make_new_address") }}</b-form-checkbox>
      <div v-if="newAddress">
        <b-form-checkbox id="checkbox-1" v-model="isCompany" name="checkbox-1">{{ $t("form_are_you_a_company")
          }}</b-form-checkbox>
        <div v-if="isCompany" class="short-form">
          <b-form-group class="account-inputs" id="company-group" :label="$t('form_company_name') + '*'"
            label-for="company">
            <b-form-input id="company" :placeholder="$t('form_company_name')" v-model="form.company" type="text"
              v-on:input="validateCompany" v-on:change="validateCompany" :state="form.companyState"
              aria-describedby="company-feedback"></b-form-input>
            <b-form-invalid-feedback id="company-feedback">
              {{ form.companyError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="account-inputs" id="coc_number-group" :label="$t('form_coc_number') + '*'"
            label-for="coc_number">
            <b-form-input id="coc_number" :placeholder="$t('form_coc_number')" v-model="form.coc_number" type="text"
              v-on:input="validateCOCNumber" v-on:change="validateCOCNumber" :state="form.coc_numberState"
              aria-describedby="coc_number-feedback"></b-form-input>
            <b-form-invalid-feedback id="coc_number-feedback">
              {{ form.coc_numberError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="account-inputs" id="taxvat-group" :label="$t('form_vat_number') + '*'"
            label-for="taxvat">
            <b-form-input id="taxvat" :placeholder="$t('form_vat_number')" v-model="form.taxvat" type="text"
              :state="form.taxvatState" v-on:input="validateCOCNumber" v-on:change="validateCOCNumber"
              aria-describedby="taxvat-feedback"></b-form-input>
            <b-form-invalid-feedback id="taxvat-feedback">
              {{ form.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group class="account-inputs" id="gender-group" :label="$t('form_gender') + '*'" label-for="gender">
          <b-form-select id="gender" v-model="form.gender" :options="genderOptions" :state="form.genderState"
            class="select" v-on:change="validateGender"></b-form-select>
        </b-form-group>
        <b-form-group class="account-inputs" id="first_name-group" :label="$t('form_first_name') + '*'"
          label-for="first_name">
          <b-form-input id="first_name" :placeholder="$t('form_first_name')" v-model="form.firstname" type="text"
            v-on:input="validateFirstname" v-on:change="validateFirstname" :state="form.firstnameState"
            aria-describedby="firstname-feedback"></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="account-inputs" id="last_name-group" :label="$t('form_last_name') + '*'"
          label-for="last_name">
          <b-form-input id="last_name" :placeholder="$t('form_last_name')" v-model="form.lastname" type="text"
            v-on:input="validateLastname" v-on:change="validateLastname" :state="form.lastnameState"
            aria-describedby="lastname-feedback"></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="account-inputs" id="country-group" :label="$t('form_country') + '*'" label-for="country">
          <b-form-select id="country" v-model="form.address.country_code" :options="countries"
            class="select"></b-form-select>
        </b-form-group>
        <b-form-group v-if="!showFullAddress" class="account-inputs" id="postcode-group"
          :label="$t('form_postal_code') + '*'" label-for="postcode">
          <b-form-input id="postcode" :placeholder="$t('form_postal_code')" v-model="form.address.postcode" type="text"
            :state="form.address.postcodeState" v-on:input="validateCOCNumber" v-on:change="validateCOCNumber"
            aria-describedby="address.postcode-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.postcode-feedback">
            {{ form.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-show="showFullAddress" class="account-inputs" id="street-group" :label="$t('form_street') + '*'"
          label-for="street">
          <b-form-input id="street" :placeholder="$t('form_street')" v-model="form.address.streetDisplay" type="text"
            v-on:input="validateStreet" v-on:change="validateStreet" :state="form.address.streetDisplayState"
            aria-describedby="address.streetDisplay-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.streetDisplay-feedback">
            {{ form.address.streetDisplayError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!-- <b-row>
          <b-col sm="6" class="first">
            <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number') + '*'"
              label-for="house_number">
              <b-form-input id="house_number" :placeholder="$t('form_company_name')" v-model="form.address.house_number" type="text" v-on:input="validateCOCNumber" v-on:change="validateCOCNumber"
                v-on:blur="validateHouseNumber()" :state="form.address.house_numberState"
                aria-describedby="address.house_number-feedback"></b-form-input>
              <b-form-invalid-feedback id="address.house_number-feedback">
                {{ form.address.house_numberError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6" class="third">
            <b-form-group class="account-inputs" id="addition-group" :label="$t('form_addition')" label-for="addition">
              <b-form-input id="addition" :placeholder="$t('form_company_name')" v-model="form.address.addition" type="text"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row> -->

        <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number_and_addition')"
          label-for="invoice_house_number_and_addition">
          <b-form-input :placeholder="$t('form_house_number_and_addition')" id="invoice_house_number_and_addition"
            name="house_number_and_addition" v-model="form.address.house_number_and_addition" type="text"
            v-on:input="validateHouseNumberAndAddition" v-on:change="validateHouseNumberAndAddition"
            :state="form.address.house_number_and_additionState"
            aria-describedby="address.house_number_and_addition-feedback" ref="addressHouseNumber"></b-form-input>
          <b-form-invalid-feedback id="address.house_number_and_addition-feedback">
            {{ form.address.house_number_and_additionError }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-show="showFullAddress" class="account-inputs" id="postcode-group1"
          :label="$t('form_postal_code') + '*'" label-for="postcode1">
          <b-form-input id="postcode1" :placeholder="$t('form_postal_code')" v-model="form.address.postcode" type="text"
            v-on:input="validatePostcode" v-on:change="validatePostcode" :state="form.address.postcodeState"
            aria-describedby="address.postcode1-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.postcode1-feedback">
            {{ form.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-show="showFullAddress" class="account-inputs" id="city-group" :label="$t('form_city') + '*'"
          label-for="city">
          <b-form-input id="city" :placeholder="$t('form_city')" v-model="form.address.city" type="text"
            v-on:input="validateCity" v-on:change="validateCity" :state="form.address.cityState"
            aria-describedby="address.city-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.city-feedback">
            {{ form.address.cityError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="foundAddress != '' && showFullAddress == false" class="account-inputs" id="add-group"
          label=" " label-for="add">
          <div id="add">
            {{ foundAddress }}
          </div>
        </b-form-group>
        <b-form-group v-if="foundAddresses.length > 0 && showFullAddress == false" class="account-inputs"
          id="addm-group" label=" " label-for="addm">
          <div id="addm">
            <b-form-radio style="width: 300px" class="account-radios" v-model="selectedAddress"
              v-for="(item, index) in foundAddresses" :key="index" name="some-radios" :value="index">{{ item.text
              }}</b-form-radio>
          </div>
        </b-form-group>
        <b-form-checkbox v-if="showManual" id="manual" v-model="showFullAddress" name="manual">{{
          $t("form_fillout_address_manual") }}</b-form-checkbox>
        <b-form-group class="account-inputs" id="telephone-group" :label="$t('form_phone') + '*'" label-for="telephone">
          <b-form-input id="telephone" :placeholder="$t('form_phone')" v-model="form.address.telephone" type="text"
            v-on:input="validatePhone" v-on:change="validatePhone" :state="form.address.telephoneState"
            aria-describedby="address.telephone-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.telephone-feedback">
            {{ form.address.telephoneError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <b-form-checkbox id="checkbox-2" name="checkbox-2" label-for="checkbox2" v-model="form.address.default_billing">{{
        $t("form_address_also_invoice_address") }}</b-form-checkbox>
    </div>
    <div class="group" v-if="form.address.default_billing == false">
      <span class="group-title d-block mb-25"><i class="lnr lnr-home mr-10"></i>{{ $t("invoice_address") }}</span>

      <div v-for="(address, index) in addresses" :key="`u-${index}`">
        <div @click="selectInvoiceAddress(address.id)" class="address-item border rounded p-5 m-5"
          :class="address.id == selectedBillingAddressId ? 'selected-item' : ''">
          <div v-if="address.company != null">{{ address.company }}</div>
          <div>{{ address.firstname }} {{ address.lastname }}</div>
          <div>{{ address.street[0] }}</div>
          <div>{{ address.postcode }} {{ address.city }}</div>
          <div>{{ address.telephone }}</div>
        </div>
      </div>
      <b-form-checkbox id="new-invoice-address" name="new-invoice-address" label-for="new-invoice-address"
        v-model="newInvoiceAddress">{{ $t("make_new_address") }}</b-form-checkbox>
      <div v-if="newInvoiceAddress">
        <b-form-checkbox id="checkbox-99" v-model="isInvoiceCompany" name="checkbox-99">{{ $t("form_are_you_a_company")
          }}</b-form-checkbox>
        <div v-if="isInvoiceCompany" class="short-form">
          <b-form-group class="account-inputs" id="company-group" :label="$t('form_company_name') + '*'"
            label-for="company">
            <b-form-input id="company" :placeholder="$t('form_company_name')" v-model="form.invoice.company" type="text"
              v-on:input="validateInvoiceCompany" v-on:change="validateInvoiceCompany"
              :state="form.invoice.companyState" aria-describedby="company-feedback"></b-form-input>
            <b-form-invalid-feedback id="company-feedback">
              {{ form.invoice.companyError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="account-inputs" id="coc_number-group" :label="$t('form_coc_number') + '*'"
            label-for="coc_number">
            <b-form-input id="coc_number" :placeholder="$t('form_coc_number')" v-model="form.invoice.coc_number"
              type="text" v-on:input="validateInvoiceCOCNumber" v-on:change="validateInvoiceCOCNumber"
              :state="form.invoice.coc_numberState" aria-describedby="coc_number-feedback"></b-form-input>
            <b-form-invalid-feedback id="coc_number-feedback">
              {{ form.invoice.coc_numberError }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="account-inputs" id="taxvat-group" :label="$t('form_vat_number') + '*'"
            label-for="taxvat">
            <b-form-input id="taxvat" :placeholder="$t('form_vat_number')" v-model="form.invoice.taxvat" type="text"
              :state="form.invoice.taxvatState" v-on:input="validateCOCNumber" v-on:change="validateCOCNumber"
              aria-describedby="taxvat-feedback"></b-form-input>
            <b-form-invalid-feedback id="taxvat-feedback">
              {{ form.invoice.taxvatError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <b-form-group class="account-inputs" id="first_name-group" :label="$t('form_first_name') + '*'"
          label-for="first_name">
          <b-form-input id="first_name" :placeholder="$t('form_first_name')" v-model="form.invoice.firstname"
            type="text" v-on:input="validateInvoiceFirstname" v-on:change="validateInvoiceFirstname"
            :state="form.invoice.firstnameState" aria-describedby="firstname-feedback"></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.invoice.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="account-inputs" id="last_name-group" :label="$t('form_last_name') + '*'"
          label-for="last_name">
          <b-form-input id="last_name" :placeholder="$t('form_last_name')" v-model="form.invoice.lastname" type="text"
            v-on:input="validateInvoiceLastname" v-on:change="validateInvoiceLastname"
            :state="form.invoice.lastnameState" aria-describedby="lastname-feedback"></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.invoice.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="account-inputs" id="country-group" :label="$t('form_country') + '*'" label-for="country">
          <b-form-select id="country" v-model="form.invoice.address.country_code" :options="countries"
            class="select"></b-form-select>
        </b-form-group>
        <b-form-group v-if="!showFullInvoiceAddress" class="account-inputs" id="postcode-group"
          :label="$t('form_postal_code') + '*'" label-for="postcode">
          <b-form-input id="postcode" :placeholder="$t('form_postal_code')" v-model="form.invoice.address.postcode"
            type="text" v-on:input="validateCOCNumber" v-on:change="validateCOCNumber"
            :state="form.invoice.address.postcodeState" aria-describedby="address.postcode-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.postcode-feedback">
            {{ form.invoice.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="street-group"
          :label="$t('form_street') + '*'" label-for="street">
          <b-form-input id="street" :placeholder="$t('form_street')" v-model="form.invoice.address.streetDisplay"
            type="text" v-on:input="validateInvoiceStreet" v-on:change="validateInvoiceStreet"
            :state="form.invoice.address.streetDisplayState"
            aria-describedby="address.streetDisplay-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.streetDisplay-feedback">
            {{ form.invoice.address.streetDisplayError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!-- <b-row>
          <b-col sm="6" class="first">
            <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number') + '*'"
              label-for="house_number">
              <b-form-input id="house_number" :placeholder="$t('form_house_number')" v-model="form.invoice.address.house_number" type="text" v-on:input="validateCOCNumber" v-on:change="validateCOCNumber"
                v-on:blur="validateInvoiceHouseNumber()" :state="form.invoice.address.house_numberState"
                aria-describedby="address.house_number-feedback"></b-form-input>
              <b-form-invalid-feedback id="address.house_number-feedback">
                {{ form.invoice.address.house_numberError }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="6" class="third">
            <b-form-group class="account-inputs" id="addition-group" :label="$t('form_addition')" label-for="addition">
              <b-form-input id="addition" :placeholder="$t('form_addition')" v-model="form.invoice.address.addition" type="text"></b-form-input> 
            </b-form-group>
          </b-col>
        </b-row> -->

        <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number_and_addition')"
          label-for="house_number_and_addition">
          <b-form-input :placeholder="$t('form_house_number_and_addition')" id="house_number_and_addition"
            name="house_number_and_addition" v-model="form.invoice.address.house_number_and_addition" type="text"
            v-on:input="validateInvoiceHouseNumberAndAddition" v-on:change="validateInvoiceHouseNumberAndAddition"
            :state="form.invoice.address.house_number_and_additionState"
            aria-describedby="address.house_number_and_addition-feedback" ref="addressHouseNumber"></b-form-input>
          <b-form-invalid-feedback id="address.house_number_and_addition-feedback">
            {{ form.invoice.address.house_number_and_additionError }}
          </b-form-invalid-feedback>
        </b-form-group>


        <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="postcode-group1"
          :label="$t('form_postal_code') + '*'" label-for="postcode1">
          <b-form-input id="postcode1" :placeholder="$t('form_postal_code')" v-model="form.invoice.address.postcode"
            type="text" v-on:input="validateInvoicePostcode" v-on:change="validateInvoicePostcode"
            :state="form.invoice.address.postcodeState" aria-describedby="address.postcode1-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.postcode1-feedback">
            {{ form.invoice.address.postcodeError }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="city-group"
          :label="$t('form_city') + '*'" label-for="city">
          <b-form-input id="city" :placeholder="$t('form_city')" v-model="form.invoice.address.city" type="text"
            v-on:input="validateInvoiceCity" v-on:change="validateInvoiceCity" :state="form.invoice.address.cityState"
            aria-describedby="address.city-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.city-feedback">
            {{ form.invoice.address.cityError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="foundInvoiceAddress != '' && showFullInvoiceAddress == false" class="account-inputs"
          id="add-group" label=" " label-for="add">
          <div id="add">
            {{ foundInvoiceAddress }}
          </div>
        </b-form-group>
        <b-form-group v-if="
          foundInvoiceAddresses.length > 0 && showFullInvoiceAddress == false
        " class="account-inputs" id="addm-group" label=" " label-for="addm">
          <div id="addm">
            <b-form-radio style="width: 300px" class="account-radios" v-model="selectedInvoiceAddress"
              v-for="(item, index) in foundInvoiceAddresses" :key="index" name="some-radios" :value="index">{{ item.text
              }}</b-form-radio>
          </div>
        </b-form-group>
        <b-form-checkbox v-if="showManual" id="manual2" v-model="showFullInvoiceAddress" name="manual2">{{
          $t("form_fillout_address_manual") }}</b-form-checkbox>
        <b-form-group class="account-inputs" id="telephone-group" :label="$t('form_phone') + '*'" label-for="telephone">
          <b-form-input id="telephone" :placeholder="$t('form_phone')" v-model="form.invoice.address.telephone"
            type="text" v-on:input="validateInvoicePhone" v-on:change="validateInvoicePhone"
            :state="form.invoice.address.telephoneState" aria-describedby="address.telephone-feedback"></b-form-input>
          <b-form-invalid-feedback id="address.telephone-feedback">
            {{ form.invoice.address.telephoneError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import {
  validatePostcode,
  validateVatNumber,
  checkEmailExist,
} from "@storefront/core/data-resolver/user";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "LoggedInCheckoutDetails",
  mixins: [Cart],
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
    this.selectedShippingAddress()
    this.selectedBillingAddress()
    if (this.shippingAddress == null) {
      this.selectAddress(this.user.default_shipping);
    }
  },
  methods: {
    async checkDeliveryAddressFields() {
      this.validateEmail();
      this.validateFirstname();
      this.validateLastname();
      this.validateAddress();
      this.validateHouseNumberAndAddition();
      this.validatePhone();
      this.validateStreet();
      this.validatePostcode();
      this.validateCity();
      await this.checkFieldsLoggedin();
    },
    checkFieldsLoggedin() {
      var form = this.form;
      var errorFields = [];
      var skipFields = [
        "email",
        "taxvat",
        "streetDisplay",
        "password",
        "password_confirm",
        "coc_number",
        "house_number",
        "city",
        "house_number_and_addition"
      ];
      Object.keys(form).forEach((name) => {
        if (typeof form[name] == "object" && !Array.isArray(form[name]) && form[name] !== null) {
          if (name == "invoice" && form.address.default_billing) {
            return;
          }
          Object.keys(form[name]).forEach((child) => {
            if (child == "companyState" && name == "address") {
              if (!this.isCompany) {
                return;
              }
            }
            if (child == "companyState" && name == "invoice") {
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (child.includes("State") && (form[name][child] == false || form[name][child] == null)) {
              if (child == "companyState" && name == "address") {
                if (!this.isCompany) {
                  return;
                }
              }
              if (child == "companyState" && name == "invoice") {
                if (!this.isInvoiceCompany) {
                  return;
                }
              }
              if (skipFields.includes(child.replace("State", ""))) {
                return;
              }
              switch (name) {
                case "invoice":
                  child = "invoice_" + child;
                  break;
                case "address":
                  child = "shipping_" + child;
                  break;
              }
              errorFields.push(this.$t(child.replace("State", "")));
            }
          });
        } else {
          if (name.includes("State") && (form[name] == false || form[name] == null)) {
            if (name == "companyState") {
              if (!this.isCompany) {
                return;
              }
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (skipFields.includes(name.replace("State", ""))) {
              return;
            }
            errorFields.push(this.$t(name.replace("State", "")));
          }
        }
        if (errorFields.length > 0) {
          this.$store.commit("cart/setStoreValidation", {
            address: errorFields,
          });
        } else {
          this.$store.commit("cart/setStoreValidation", {
            address: [],
          });
        }
      });

    },
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.emailExists = true;
      } else {
        this.emailExists = false;
      }

      this.form.emailState = true;
      this.$store.dispatch("cart/addCartEmail", {
        email: this.form.email,
      });
      return true;
    },
    async doLogin() {
      const loginOk = await this.$store.dispatch("user/login", {
        username: this.form.email,
        password: this.form.password,
      });

      if (loginOk == true) {
        const msg = {
          type: "success",
          title: this.$t("login_success"),
          text: this.$t("you_are_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    async validateVAT() {
      if (this.isCompany == true) {
        if (
          this.form.taxvat.substring(0, 2).toUpperCase() ===
          this.form.address.country_code
        ) {
          const retval = await validateVatNumber(this.form.taxvat);
          if (retval.valid == "true") {
            this.isValidVatNumber = true;
            this.form.taxvatState = true;
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_invalid");

            this.showVatMessage = this.$t("taxvat_invalid");
          }
        } else {
          this.isValidVatNumber = false;
          this.form.taxvatState = false;
          this.form.taxvatError = this.$t("taxvat_wrong_country");
        }
      } else {
        this.form.taxvatState = null;
      }
    },
    async validateAddress() {
      let isOk = true;
      if (this.isCompany == true) {
        if (this.form.company == "") {
          isOk = false;
        }
        if (this.form.taxvat == "") {
          isOk = false;
        }
      }
      if (this.form.firstname == "") {
        isOk = false;
      }
      if (this.form.gender == "") {
        isOk = false;
      }
      if (this.form.lastname == "") {
        isOk = false;
      }
      if (this.form.address.country_code == "") {
        isOk = false;
      }
      if (this.form.address.postcode == "") {
        isOk = false;
      }
      if (this.form.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.address.house_number_and_addition == "") {
        isOk = false;
      }
      if (this.form.address.city == "") {
        isOk = false;
      }
      if (this.form.address.telephone == "") {
        isOk = false;
      }
      if (isOk == true) {
        const address = {
          prefix: this.form.gender,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          postcode: this.form.address.postcode,
          city: this.form.address.city,
          telephone: this.form.address.telephone,
          street: `${this.form.address.streetDisplay} ${this.form.address.house_number_and_addition}`,
          country_code: this.form.address.country_code,
        };
        if (this.isCompany == true) {
          (address.company = this.form.company),
            (address.vat_id = this.form.taxvat);
        }
        await this.$store.dispatch("cart/addShippingAddress", {
          address: address,
          reloadCart: false,
        });
        if (this.form.address.default_billing == true) {
          await this.$store.dispatch("cart/addBillingAddress", {
            address: address,
            reloadCart: true,
          });
        }
      }
    },
    async validateInvoiceAddress() {
      let isOk = true;
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          isOk = false;
        }
        if (this.form.invoice.taxvat == "") {
          isOk = false;
        }
      }
      if (this.form.invoice.firstname == "") {
        isOk = false;
      }
      if (this.form.invoice.lastname == "") {
        isOk = false;
      }
      if (this.form.invoice.address.country_code == "") {
        isOk = false;
      }
      if (this.form.invoice.address.postcode == "") {
        isOk = false;
      }
      if (this.form.invoice.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.invoice.address.house_number_and_addition == "") {
        isOk = false;
      }
      if (this.form.invoice.address.city == "") {
        isOk = false;
      }
      if (this.form.invoice.address.telephone == "") {
        isOk = false;
      }

      if (isOk == true) {
        const address = {
          company: this.form.invoice.company,
          firstname: this.form.invoice.firstname,
          lastname: this.form.invoice.lastname,
          postcode: this.form.invoice.address.postcode,
          city: this.form.invoice.address.city,
          telephone: this.form.invoice.address.telephone,
          street: `${this.form.invoice.address.streetDisplay} ${this.form.invoice.address.house_number_and_addition}${this.form.invoice.address.addition}`,
          country_code: this.form.invoice.address.country_code,
        };
        if (this.isCompany == true) {
          (address.company = this.form.company),
            (address.vat_id = this.form.taxvat);
        }

        await this.$store.dispatch("cart/addBillingAddress", {
          address: address,
        });
      }
    },
    validateCompany() {
      if (this.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    validateGender() {
      this.form.genderState = true;
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateCOCNumber() {
      if (this.isCompany == true) {
        if (this.form.coc_number == "") {
          this.form.coc_numberState = false;
          this.form.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.coc_numberState = true;
        }
      } else {
        this.form.coc_numberState = null;
      }
    },
    validateStreet() {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateHouseNumber() {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        this.form.address.house_numberState = true;
      }
    },
    validateHouseNumberAndAddition() {
      if (this.form.address.house_number_and_addition == "") {
        this.form.address.house_number_and_additionState = false;
        this.form.address.house_number_and_additionError = this.$t("house_number_and_addition_required");
      } else {
        this.form.address.house_number_and_additionState = true;
      }
    },
    validateCity() {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number_and_addition
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState == true;
          this.foundAddress = address;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
        } else {
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + item.letter;
            }

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            this.foundAddresses.push(item);
          }, this);
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
    },
    validatePostcode() {
      const countryRegexData = config.postalCodeRegex;
      const countryRegex = countryRegexData[this.form.address.country_code]
      if (countryRegex) {
        const pattern = new RegExp(countryRegex);

        if (pattern.test(this.form.address.postcode)) {
          this.form.address.postcodeState = true;
        } else {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
    },
    async validateInvoiceVAT() {
      if (this.isInvoiceCompany == true) {
        if (
          this.form.invoice.taxvat.substring(0, 2).toUpperCase() ===
          this.form.invoice.address.country_code
        ) {
          const retval = await validateVatNumber(this.form.invoice.taxvat);
          if (retval.valid == "true") {
            this.isValidInvoiceVatNumber = true;
            this.form.invoice.taxvatState = true;
          } else {
            this.isValidInvoiceVatNumber = false;
            this.form.invoice.taxvatState = false;
            this.form.invoice.taxvatError = this.$t("taxvat_invalid");
          }
        } else {
          this.isValidInvoiceVatNumber = false;
          this.form.invoice.taxvatState = false;
          this.form.invoice.taxvatError = this.$t("taxvat_wrong_country");
        }
      } else {
        this.form.invoice.taxvatState = null;
      }
    },
    validateInvoiceCompany() {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          this.form.invoice.companyState = false;
          this.form.invoice.companyError = this.$t("company_name_required");
        } else {
          this.form.invoice.companyState = true;
        }
      } else {
        this.form.invoice.companyState = null;
      }
    },
    validateInvoiceFirstname() {
      if (this.form.invoice.firstname == "") {
        this.form.invoice.firstnameState = false;
        this.form.invoice.firstnameError = this.$t("firstname_required");
      } else {
        this.form.invoice.firstnameState = true;
      }
    },
    validateInvoiceLastname() {
      if (this.form.invoice.lastname == "") {
        this.form.invoice.lastnameState = false;
        this.form.invoice.lastnameError = this.$t("lastname_required");
      } else {
        this.form.invoice.lastnameState = true;
      }
    },
    validateInvoiceCOCNumber() {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.coc_number == "") {
          this.form.invoice.coc_numberState = false;
          this.form.invoice.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.invoice.coc_numberState = true;
        }
      } else {
        this.form.invoice.coc_numberState = null;
      }
    },
    validateInvoiceStreet() {
      if (this.form.invoice.address.streetDisplay == "") {
        this.form.invoice.address.streetDisplayState = false;
        this.form.invoice.address.streetDisplayError =
          this.$t("street_required");
      } else {
        this.form.invoice.address.streetDisplayState = true;
      }
    },
    validateInvoiceHouseNumber() {
      if (this.form.invoice.address.house_number == "") {
        this.form.invoice.address.house_numberState = false;
        this.form.invoice.address.house_numberError = this.$t(
          "house_number_required"
        );
      } else {
        this.form.invoice.address.house_numberState = true;
      }
    },
    validateInvoiceHouseNumberAndAddition() {
      if (this.form.invoice.address.house_number_and_addition == "") {
        this.form.invoice.address.house_number_and_additionState = false;
        this.form.invoice.address.house_number_and_additionError = this.$t("house_number_and_addition_required");
      } else {
        this.form.invoice.address.house_number_and_additionState = true;
      }
    },
    validateInvoiceCity() {
      if (this.form.invoice.address.city == "") {
        this.form.invoice.address.cityState = false;
        this.form.invoice.address.cityError = this.$t("city_required");
      } else {
        this.form.invoice.address.cityState = true;
      }
    },
    validateInvoicePhone() {
      if (this.form.invoice.address.telephone == "") {
        this.form.invoice.address.telephoneState = false;
        this.form.invoice.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.invoice.address.telephoneState = true;
      }
    },
    async validateInvoicePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.invoice.address.postcode,
          this.form.invoice.address.house_number_and_addition
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.invoice.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.invoice.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.invoice.address.addition = this.form.invoice.address.house_number_and_addition;
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.invoice.address.city = list[0].city;
          this.form.invoice.address.cityState == true;
          this.foundInvoiceAddress = address;
          this.validatePostcode();
          this.validateInvoiceHouseNumberAndAddition();
        } else if (list.length == 0) {
          this.foundInvoiceAddress = this.$t("postcode_not_found");
        } else {
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + item.letter;
            }

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            this.foundInvoiceAddresses.push(item);
          }, this);
        }
      } else {
        if (this.form.invoice.address.postcode == "") {
          this.form.invoice.address.postcodeState = false;
          this.form.invoice.address.postcodeError =
            this.$t("postcode_required");
        } else {
          this.form.invoice.address.postcodeState = true;
        }
      }
    },
    validateInvoicePostcode() {
      const countryRegexData = config.postalCodeRegex;
      const countryRegex = countryRegexData[this.form.invoice.address.country_code]
      if (countryRegex) {
        const pattern = new RegExp(countryRegex)
        if (pattern.test(this.form.invoice.address.postcode)) {
          this.form.invoice.address.postcodeState = true;
        } else {
          this.form.invoice.address.postcodeState = false;
          this.form.invoice.address.postcodeError = this.$t("postcode_required");
        }
      } else {
        if (this.form.invoice.address.postcode == "") {
          this.form.invoice.postcodeState = false;
          this.form.invoice.postcodeError = this.$t("postcode_required");
        } else {
          this.form.invoice.postcodeState = true;
        }
      }
    },
    selectAddress(id) {
      if (id) {
        this.$store.dispatch("cart/addShippingAddressById", { id: id });
        this.selectedShippingAddressId = id;
        if (this.form.address.default_billing == true) {
          this.$store.dispatch("cart/addBillingAddressById", { id: id });
        }
        this.newAddress = false
      }
    },
    selectInvoiceAddress(id) {
      this.$store.dispatch("cart/addBillingAddressById", { id: id });
    },
    selectedShippingAddress() {
      const sa = this.$store.getters["cart/getShippingAddress"];
      if (sa != null && sa.id != null) {
        this.selectedShippingAddressId = sa.id;
        this.newAddress = false
        return;
      }
      this.newAddress = true
      this.selectedShippingAddressId = 0;
      return;
    },
    selectedBillingAddress() {
      const sa = this.$store.getters["cart/getBillingAddress"];
      if (sa != null) {
        this.selectedBillingAddressId = sa.id;
        this.newInvoiceAddress = false;
        return sa.id;
      }
      this.newInvoiceAddress = true;
      this.selectedBillingAddressId = 0;
      return 0;
    },
  },
  computed: {
    shippingAddress() {
      return this.$store.getters["cart/getShippingAddress"];
    },
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    address() {
      return `${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.address.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.house_number_and_addition}|${this.form.address.addition}|${this.form.address.telephone}||${this.form.address.city}`;
    },
    invoiceAddress() {
      return `${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.house_number_and_addition}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}||${this.form.invoice.address.city}`;
    },
    invoiceCountry() {
      return this.form.invoice.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number_and_addition}`;
    },
    invoicePostcodeHouseNumber() {
      return `${this.form.invoice.address.postcode}|${this.form.invoice.address.house_number_and_addition}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    invoiceVatNumber() {
      return this.form.invoice.taxvat;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    addresses() {
      const user = this.$store.getters["user/getCurrentUser"];
      return user.addresses;
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  watch: {
    country() {
      if (config.postcode_validation.enabled) {
        if (!config.postcode_validation.countries.includes(this.country)) {
          this.showFullAddress = true;
        } else {
          this.showFullAddress = false;
        }
      } else {
        this.showFullAddress = true;
      }
    },
    address() {
      if (this.form.addressTimer !== null) {
        clearTimeout(this.form.addressTimer);
      }
      this.form.addressTimer = setTimeout(
        function (scope) {
          scope.validateAddress();
        },
        1000,
        this
      );
    },
    invoiceAddress() {
      if (this.form.invoiceAddressTimer !== null) {
        clearTimeout(this.form.invoiceAddressTimer);
      }
      this.form.invoiceAddressTimer = setTimeout(
        function (scope) {
          scope.validateInvoiceAddress();
        },
        1000,
        this
      );
    },
    invoiceCountry() {
      if (config.postcode_validation.enabled) {
        if (
          !config.postcode_validation.countries.includes(this.invoiceCountry)
        ) {
          this.showFullInvoiceAddress = true;
        } else {
          this.showFullInvoiceAddress = false;
        }
      } else {
        this.showFullInvoiceAddress = true;
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    invoiceVatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.invoice.taxvatTimer !== null) {
          clearTimeout(this.form.invoice.taxvatTimer);
        }
        this.form.invoice.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateInvoiceVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    invoicePostcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundInvoiceAddress != "") {
          this.foundInvoiceAddress = "";
        }
        if (this.foundInvoiceAddresses.length > 0) {
          this.foundInvoiceAddresses = [];
        }
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validateInvoicePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    selectedAddress(newVal) {
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;
      if (this.foundAddresses[newVal].letter != null) {
        this.form.address.addition = this.foundAddresses[newVal].letter;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
    selectedInvoiceAddress(newVal) {
      this.form.invoice.address.streetDisplay =
        this.foundInvoiceAddresses[newVal].street;
      if (this.foundInvoiceAddresses[newVal].letter != null) {
        this.form.invoice.address.addition =
          this.foundInvoiceAddresses[newVal].letter;
      } else {
        this.form.invoice.address.addition = "";
      }
      this.form.invoice.address.city = this.foundAddresses[newVal].city;
      this.validateInvoicePostcode();
    },
    Email() {
      if (this.form.emailTimer !== null) {
        clearTimeout(this.form.emailTimer);
      }
      this.form.emailTimer = setTimeout(
        function (scope) {
          scope.validateEmail();
        },
        1000,
        this
      );
    },
    newAddress() {
      if (this.newAddress == true) {
        this.selectedShippingAddressId = 0
        this.checkFieldsLoggedin();
      } else {
        this.$store.commit("cart/setStoreValidation", {
          address: [],
        });
        this.selectedShippingAddress()
      }
    },
    newInvoiceAddress() {
      if (this.newInvoiceAddress == true) {
        this.selectedBillingAddressId = 0
        this.checkFieldsLoggedin();
      } else {
        this.$store.commit("cart/setStoreValidation", {
          address: [],
        });
        this.selectedBillingAddress()
      }
    }
  },
  data() {
    return {
      newAddress: false,
      selectedShippingAddressId: null,
      selectedBillingAddressId: null,
      newInvoiceAddress: false,
      isCompany: false,
      isInvoiceCompany: false,
      isValidVatNumber: false,
      isValidInvoiceVatNumber: false,
      showFullAddress: false,
      showFullInvoiceAddress: false,
      showVatMessage: "",
      showVatCheck: false,
      addressTimer: null,
      invoiceAddressTimer: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      emailExists: false,
      selectedAddress: null,
      selectedInvoiceAddress: null,
      genderOptions: [
        { value: this.$t('male'), text: this.$t('male') },
        { value: this.$t('female'), text: this.$t('female') },
        { value: "", text: this.$t('not_specified') },
      ],
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        gender: "",
        genderState: true,
        genderError: "",
        genderTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        coc_number: "",
        coc_numberState: null,
        coc_numberError: "",
        coc_numberTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: {
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: "",
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          house_number_and_addition: "",
          house_number_and_additionState: null,
          house_number_and_additionError: "",
          house_number_and_additionTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        invoice: {
          isCompany: true,

          company: "",
          companyState: null,
          companyError: "",
          companyTimer: null,
          firstname: "",
          firstnameState: null,
          firstnameError: "",
          firstnameTimer: null,
          lastname: "",
          lastnameState: null,
          lastnameError: "",
          lastnameTimer: null,
          coc_number: "",
          coc_numberState: null,
          coc_numberError: "",
          coc_numberTimer: null,
          taxvat: "",
          taxvatState: null,
          taxvatError: "",
          taxvatTimer: null,
          address: {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: "",
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            house_number_and_addition: "",
            house_number_and_additionState: null,
            house_number_and_additionError: "",
            house_number_and_additionTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          },
        },
        checkbox2: false,
        manual: false,
      },
    };
  },
};
</script>
